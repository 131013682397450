<template>
  <div class="product-page">
    <v-row no-gutters>
      <v-col cols="12" class="page-header">
        <span class="page-title">{{ $t('Products') }}</span>
        <!-- <v-btn color="primary" @click.stop="drawer = !drawer">More filters </v-btn> -->
      </v-col>
      <v-col cols="12" class="d-flex align-center">
        <v-btn @click="modalExport = true" class="mr-2" outlined color="primary">
          <i class="mr-1 fas fa-file-export"></i>
          {{ $t('Export') }}
        </v-btn>
        <v-btn
          class="mr-2"
          v-if="showImportFunc == true"
          outlined
          color="primary"
          @click="(showModalImportProduct = true), (fileName = 'No file chosen')"
          ><i class="fas fa-download mr-1"></i>{{ $t('Import') }}
        </v-btn>
        <v-btn v-if="showImportFunc == true" color="primary" outlined @click="modalImportList = true"
          ><v-icon class="mr-1">mdi-cached</v-icon>Import List</v-btn
        >
        <v-progress-circular v-if="startExport" class="ml-5" :size="42" indeterminate color="primary" :value="50"
          >{{ loadingPercent }}%</v-progress-circular
        >
        <v-spacer></v-spacer>
        <v-menu v-if="showImportFunc == true" offset-y bottom origin="center center" transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="mr-2" dark outlined v-bind="attrs" v-on="on">
              <i class="fas fa-plus mr-1"></i>{{ $t('Add from Services') }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="$router.push({ name: 'website.apps.print-hub.catalog' })">
              <v-list-item-title>
                <div class="d-flex align-items-center justify-content-center">
                  <v-btn tile color="primary" class="px-0 print-hub"><i class="fas fa-tshirt"></i></v-btn>
                  <div class="px-1">
                    <div>{{ $t('Add Print On Demand Products') }}</div>
                    <!-- <div class="caption">{{ $t('Base cost only from $5') }}</div> -->
                  </div>
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'website.apps.ali-connector.import' })">
              <v-list-item-title>
                <div class="d-flex align-items-center justify-content-center">
                  <v-btn tile color="primary" class="px-0 print-hub"><i class="fas fa-tshirt"></i></v-btn>
                  <div class="px-1">
                    <div>{{ $t('Add AliExpress Products ') }}</div>
                    <!-- <div class="caption">{{ $t('Base cost only from $5') }}</div> -->
                  </div>
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn v-if="showImportFunc == true" color="primary" @click="$router.push({ name: 'website.products.create' })"
          ><i class="fas fa-plus mr-1"></i>{{ $t('Add product') }}</v-btn
        >
        <v-btn v-else color="primary" @click="$router.push({ name: 'website.catalog' })"
          ><i class="fas fa-plus mr-1"></i>{{ $t('Add product') }}</v-btn
        >
      </v-col>
    </v-row>
    <Products ref="products" />
    <!-- <v-dialog persistent v-model="showWarning" width="500">
      <v-card class="py-5">
        <v-card-subtitle
          >The export process is active, you can wait for the download or check your email to get the download
          link</v-card-subtitle
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="showWarning = false"> Close </v-btn>
          <v-btn :loading="isLoading" color="primary" @click="(showWarning = false), (showLoadingPercent = false)"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="showLoadingPercent" persistent width="700" height="300">
      <v-card>
        <v-card-title class="headline">The product is being exported</v-card-title>
        <div class="close-icon mr-3" @click="showLoadingPercent = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-card-text class="">You can wait for the download or check your email to get the download link</v-card-text>
        <!-- <v-card-title class="headline">Exporting products is in progress.</v-card-title>
        <div class="close-icon" @click="showWarning = true">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-card-subtitle class="mt-3"> Your export will be downloaded automatically after finish</v-card-subtitle>
        <v-row class="py-8" d-flex justify="center" no-gutters>
          <v-col md="8">
            <v-progress-linear color="primary" :value="loadingPercent"></v-progress-linear>
          </v-col>
        </v-row> -->
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="showModalImportProduct" width="700" style="transform-origin: center top">
      <v-card relative>
        <!-- Title -->
        <v-card-title class="headline">Import products by CSV file </v-card-title>
        <!-- Close icon -->
        <div class="close-icon" @click="(showModalImportProduct = false), (overwrite = true)">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-divider class="mb-3"></v-divider>
        <v-row no-gutters d-flex justify="center">
          <v-col cols="11">
            <div>
              <v-btn elevation="" color="primary" @click="clickUploadFile"
                >Choose file
                <div class="fake-btn"><v-icon large small>mdi-publish</v-icon></div></v-btn
              >
              <span class="ml-4" style="font-weight: 700">{{ fileName }}</span>
            </div>
            <div class="mt-5">
              <p class="m-t m-b-sm">
                Download a
                <a
                  href="https://assets.shopbase.com/sample-file/product/import/product-sample-import.csv?_ga=2.177733477.1978499345.1611903298-1097588301.1611903298"
                  target="_blank"
                  >sample CSV template</a
                >
                to see an example of the format required.
              </p>
            </div>
            <v-checkbox @change="onChangeOverWrite" v-model="overwrite">
              <template v-slot:label>
                <div class="label-checkbox">Overwrite any existing products that have the same product handle.</div>
              </template>
            </v-checkbox>
            <input
              ref="fileUploader"
              @change="onChangeImage"
              class="myfile"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              type="file"
            />
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <!-- Footer -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="(showModalImportProduct = false), (overwrite = true)"> Close </v-btn>
          <v-btn :loading="isLoading" color="primary" @click="submitUploadCSV">{{ 'Import CSV' }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal export products -->
    <modal model="modalExport" title="Export products to CSV file" :callback="submitExportCSV">
      <template slot="content">
        <div>
          <!-- {{ count }} -->
          <p>This CSV file can update all product information.</p>
          <v-radio-group v-model="selectedMode">
            <v-radio value="current-page" label="Current page"></v-radio>
            <v-radio value="current-products" label="Current search"></v-radio>
            <v-radio value="all-products" label="All products"></v-radio>
            <v-radio value="selected-products" label="Selected products"></v-radio>
          </v-radio-group>
        </div>
      </template>
    </modal>
    <v-dialog v-model="downloadedModal" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Export success
        </v-card-title>
        <v-card-text class="d-flex">
          Export completed, click the following link to
          <a :href="exportedUrl"><v-icon small>mdi-download</v-icon> download</a>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="downloadedModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <modal model="modalImportList" width="700" title="Import Process" :callback="closeModalmodalImportList">
      <template slot="content">
        <div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Name</th>
                  <th class="pl-8">Process</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, ind) in importList" :key="ind">
                  <td v-if="ind < 5">{{ item.filename }}</td>
                  <v-tooltip max-width="200" right>
                    <template v-slot:activator="{ on, attrs }">
                      <td style="cursor: pointer" v-on="on" v-bind="attrs" v-if="ind < 5">
                        <v-icon color="primary" v-if="item.status == '0'">
                          mdi-language-java
                        </v-icon>
                        <v-icon color="primary" v-if="item.status == '1'">
                          mdi-download
                        </v-icon>
                        <v-icon color="primary" v-if="item.status == '2'">
                          mdi-checkbox-marked-circle
                        </v-icon>
                        <v-icon color="red" v-if="item.status == '4'">
                          mdi mdi-alert-circle
                        </v-icon>
                        {{
                          item.status == '4'
                            ? 'Error'
                            : item.status == '2'
                            ? 'Imported'
                            : item.status == '1'
                            ? 'Importing'
                            : item.status == '0'
                            ? 'Waiting'
                            : ''
                        }}
                      </td>
                    </template>
                    <span>{{ item.description ? item.description : 'This is file import status' }} </span>
                  </v-tooltip>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Products from './components/Products';
import { productApi } from '@/apis/product';
import Selectable from '../../../helpers/selectable';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';

export default {
  components: { Products },
  data() {
    return {
      importList: '',
      modalImportList: false,
      selectable: null,
      showLoadingPercent: false,
      loadingPercent: 0,
      showWarning: false,
      showModalImportProduct: false,
      overwrite: true,
      isLoading: false,
      fileName: 'No file chosen',
      modalExport: false,
      ids: [],
      selectedMode: 'current-page',
      products: [],
      search: null,
      count: '',
      updateTask: '',
      startExport: false,
      exportStatus: '',
      updatedDate: '',
      exportedUrl: '',
      isDrop: false,
      downloadedModal: false,
      paymentChoice: null,
      showImportFunc: true,
    };
  },
  computed: {
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
  },

  created() {
    this.getCount();
    this.resumeExport();
    // this.loadingPercent = 0;
    this.selectable = new Selectable(data => {
      this.$forceUpdate();
    });
  },
  mounted() {
    if (this.pagePreference && this.pagePreference.businessType === 'dropship') {
      this.isDrop = true;
    }
    this.paymentChoice = this.pagePreference?.paymentChoice?.type || 'seller';
    if (this.pagePreference && this.pagePreference.businessType === 'dropship') {
      this.isDrop = true;
    }
    // this.showImportFunc = this.paymentChoice === 'seller' || (this.paymentChoice === 'latte' && !this.isDrop);
  },
  methods: {
    async resumeExport() {
      this.exportStatus = localStorage.getItem('exportStatus');
      if (this.exportStatus == 'exporting') {
        let params = JSON.parse(localStorage.getItem('exportInformation1'));
        let searchString = localStorage.getItem('exportInformation2');
        let typeAll = localStorage.getItem('exportInformation3');
        let timeOut = localStorage.getItem('exportInformation4');
        // console.log(params);
        this.updateTask = setInterval(async () => {
          let data1 = await productApi.updateTask(params, searchString, typeAll);
          // để trả về rỗng thì k export
          if (data1.data.tasks.length > 0) {
            // this.showLoadingPercent = true;
            this.startExport = true;
            this.loadingPercent = data1.data.tasks[0].percentDone;
            let exportStatus = data1.data.tasks[0].status;
            if (exportStatus == 2) {
              clearInterval(this.updateTask);
              window.open(`${data1.data.tasks[0].outputSrc}`);
              // this.showLoadingPercent = false;
              this.startExport = false;
              this.showWarning = false;
              this.loadingPercent = 0;
              localStorage.setItem('exportStatus', 'exported');
              await productApi.taskDownloaded(data1.data.tasks[0]._id);
            }
          } else {
            clearInterval(this.updateTask);
          }
        }, timeOut);
      }
    },
    async getCount() {
      let params = {};
      let count = await productApi.count(params);
      this.count = count.data.count;
    },
    onChangeOverWrite() {
      if (this.formData) {
        this.formData.set('overwrite', this.overwrite);
      }
    },
    downloadFile(fileName, urlData) {
      var aLink = document.createElement('a');
      aLink.download = fileName;
      aLink.href = urlData;
      var event = new MouseEvent('click');
      aLink.dispatchEvent(event);
    },
    async getTaskUpdate(params) {
      let data = await productApi.updateTask(params);
      console.log(data);
    },
    stopGetTask(a) {
      clearInterval(a);
    },
    async submitExportCSV() {
      let params = {
        lazy: true,
        email: this.$store.state.auth.user.email,
      };
      let searchString = this.$refs.products.searchString;
      let typeAll = this.$refs.products.selectAll;
      let query = this.$route.query;
      if (this.selectedMode == 'current-page') {
        let ids = this.$refs.products.document.map(product => {
          return product._id;
        });
        params.ids = ids.join(',');
        params.query = query;
      } else if (this.selectedMode == 'all-products') {
        params.ids = null;
      } else if (this.selectedMode == 'current-products') {
        params.query = query;
      } else if (this.selectedMode == 'selected-products' && this.$refs.products.selectAll == 'all') {
        params.query = query;
      } else {
        params.ids = this.ids.join(',');
      }
      try {
        // TODO
        let data = await productApi.exportCSVProduct(params, searchString, typeAll);
        let totalProduct = data.data.totalProduct;
        let time = totalProduct / 250;
        let timeOut;
        if (time < 60) {
          //2s request 1 lan
          timeOut = 2000;
        } else if (time > 60 && time < 120) {
          //4s request 1 lan
          timeOut = 4000;
        } else if (time > 120 && time < 180) {
          timeOut = 6000;
          //6s request 1 lan
        } else {
          timeOut = 10000;
          //10s request 1 lan
        }
        this.showLoadingPercent = true;
        setTimeout(() => {
          this.showLoadingPercent = false;
        }, 4000);
        if (params.lazy == true) {
          let data1;
          this.updateTask = setInterval(async () => {
            this.startExport = true;
            localStorage.setItem('exportStatus', 'exporting');
            data1 = await productApi.updateTask(params, searchString, typeAll);
            this.updatedDate = data1.data.tasks[0].updatedDate;
            localStorage.setItem('exportInformation1', JSON.stringify(params));
            localStorage.setItem('exportInformation2', searchString);
            localStorage.setItem('exportInformation3', typeAll);
            localStorage.setItem('exportInformation4', timeOut);
            this.loadingPercent = data1.data.tasks[0].percentDone;
            let exportStatus = data1.data.tasks[0].status;
            // để check xem thời gian từ lúc ấn export tới hiện tại mà > 5m, thì serve crash, k tải nữa.
            let a = moment(this.updatedDate).toNow();
            console.log(a);
            if (a == 'in 5 minutes') {
              clearInterval(this.updateTask);
              //exported để sau f5 lại k export lại nữa
              localStorage.setItem('exportStatus', 'exported');
              this.startExport = false;
              this.showWarning = false;
              await productApi.taskDownloaded(data1.data.tasks[0]._id);
            }
            if (exportStatus == 2) {
              // setinterval để cập nhật status, nếu status == 2 là đã tải xong, thì clearInterval
              clearInterval(this.updateTask);
              // this.updateTime =
              this.exportedUrl = data1.data.tasks[0].outputSrc;
              this.downloadedModal = true;
              // this.showLoadingPercent = false;
              this.startExport = false;
              this.showWarning = false;
              this.loadingPercent = 0;
              localStorage.setItem('exportStatus', 'exported');
              await productApi.taskDownloaded(data1.data.tasks[0]._id);
            }
          }, timeOut);
        }
        if (!params.lazy && data.data) {
          this.exportedUrl = data.data.outputSrc;
          this.downloadedModal = true;
        }
      } catch (error) {
        console.log(`error`, error);
      }
      this.modalExport = false;
    },
    clickUploadFile() {
      this.resetImageUploader();
      this.$refs.fileUploader.click();
    },
    resetImageUploader() {
      this.$refs.fileUploader.value = '';
    },
    deleteFile() {
      this.$emit('callback', { src: null });
    },
    objectId() {
      return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));
    },

    hex(value) {
      return Math.floor(value).toString(16);
    },
    async onChangeImage(e) {
      this.formData = null;
      this.fileName = 'No file chosen';
      //   e.target.files.forEach(async file => {
      let file = e.target.files[0];
      let size = file.size;
      console.log(size);
      // if (size > 1024 * 1024 * 20) {
      //   this.$store.commit('setMessages', {
      //     messages: 'The file is larger more than 20Mb and cannot be uploaded.',
      //     type: 'error',
      //   });
      // } else {
      this.formData = new FormData();
      this.formData.append('file', file);
      this.formData.append('overwrite', this.overwrite);
      this.formData.append('email', this.$store.state.auth.user.email);
      this.fileName = e.target.files[0].name;
      // }

      //   });
    },
    async submitUploadCSV() {
      try {
        this.isLoading = true;
        await productApi.importCSVProduct(this.formData);
        // let type = file.name.split('.')[file.name.split('.').length - 1];
        // let id = this.objectId();

        this.showModalImportProduct = false;
        this.fileName = 'No file chosen';
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async loadProducts() {
      let params = {
        page: 1,
        limit: 50,
      };
      try {
        params.search = this.search;
        let data = await productApi.get(params);
        this.products = data.data.products;
      } catch (error) {
        console.log(error);
      }
      console.log(this.search);
    },
    async getImportProcess() {
      let params = {
        page: 1,
        limit: 50,
      };
      try {
        let data = await productApi.importProcess(params);
        this.importList = data.data.productCsvs;
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    closeModalmodalImportList() {
      this.modalImportList = false;
    },
  },
  watch: {
    '$store.state.pagePreference.preference'() {
      this.paymentChoice = this.pagePreference?.paymentChoice?.type || 'seller';
      if (this.pagePreference && this.pagePreference.businessType === 'dropship') {
        this.isDrop = true;
      }
      // this.showImportFunc = this.paymentChoice === 'seller' || (this.paymentChoice === 'latte' && !this.isDrop);
    },
    search: function() {
      this.loadProducts();
    },
    selectedMode: function() {
      if (this.selectedMode == 'current-page') {
        let a = moment(this.createTime).toNow();
        console.log(a);
      }
    },
    modalImportList: function() {
      if (this.modalImportList) {
        this.getImportProcess();
      }
    },
  },
};
</script>
<style lang="scss">
.myfile {
  display: none;
}
.print-hub {
  min-width: 36px !important;
}
</style>
